.controls-wrap{
    padding: 0.5em;
    position: absolute;
    top: 1em;
    left: 1em;
    z-index: 1;
    width: min-content;
}

.controls-material{
    padding: 0.5em;
    position: absolute;
    bottom: 1em;
    left: 1em;
    z-index: 1;
}

.controls-top-material {
    padding: 1em;
    position: absolute;
    top: 1em;
    right: 2em;
    z-index: 1;
}